import React from "react"
import SEO from "../components/Seo"
import Container from "../components/Container"
import Belt from "../components/Belt"
import { Title, Quote } from "../components/typography"
import {
  Container as StaticBlockContainer,
  StaticBlock,
} from "../components/typography/StaticBlock"
import { FigureContainer, Figure } from "../components/Figure"
import globuli_bw from "../images/belt/globuli_bw.jpg"
import globuli from "../images/belt/globuli.jpg"
import ganzheitlich from "../images/illustrations/ganzheitlich.jpg"
import go_inside from "../images/aktuell/go_inside.jpg"
import kontakt from "../images/aktuell/kontakt.jpg"
import corona from "../images/belt/corona.jpg"
import incense from "../images/aktuell/incense.jpg"

import bicicle from "../images/illustrations/bicicle.jpg"
import wheelChair from "../images/illustrations/wheelChair.jpg"
import boyDog from "../images/illustrations/boyDog.jpg"
import girlMother from "../images/illustrations/girlMother.jpg"

const AktuellCoronaPage = () => (
  <>
    <SEO
      title="Homöopathie"
      keywords={[
        "Homöopathie",
        "Modernste und durchdachteste Methode",
        "Ökonomisch zu behandeln",
        "Mahatma Ghandi",
        "Samuel Hahnemann",
        "Similia similibus curentur",
        "Ähnliches möge mit ähnlichem geheilt werden",
        "Klassische Homöopathie",
        "Ähnlichkeitsprinzip",
        "Individualität",
        "Konstitution",
        "Individuelle Reaktion",
        "Homöopathische Arzneien",
        "Ganzheitlich",
        "Körper und Geist",
        "Natürliche Rohstoffe",
      ]}
      description="Die Homöopathie wurde vor 200 Jahren von Samuel Hahnemann entdeckt und gegründet. Die homöopathischen Arzneien werden nach dem Ähnlichkeitsprinzip (similia similibus curentur) und nach der Individualität jedes Patienten verschrieben. Bei der Behandlung wird der Mensch als ganzes und nicht die einzelnen Krankheiten separat behandelt."
    />
    <Belt leftImage={globuli_bw} rightImage={corona} />
    <Container>
      <Quote cite="Marcel Proust">
        Die eigentlichen Entdeckungsreisen bestehen nicht im kennenlernen neuer
        Landstriche, sondern darin, etwas mit anderen Augen zu sehen.
      </Quote>
      <StaticBlockContainer>
        <StaticBlock>
          <p>
            Die Corona-Pandemie hat unseren Alltag komplett auf den Kopf
            gestellt und wir sind in einer neuen Normalität angekommen. Diese
            stellt uns vor vielen Herausforderungen und fordert von uns Stärke,
            Zuversicht, Anpassungsfähigkeit, Flexibilität und nicht zuletzt viel
            Phantasie, um das Gewohnte mal anders anzugehen.
          </p>
          <p>
            Im Sinne des Zitats von Marcel Proust, muss man nicht unbedingt auf
            Entdeckungsreisen gehen können, um Neues zu entdecken. Die
            Corona-Krise gibt uns auch die Chance, um ganz nahe, in unseren
            vertrauten vier Wänden, viel Spannendes und Neues zu entdecken,
            indem wir die Sachen mit anderen Augen betrachten : ).
          </p>
          <p>
            In Zeiten des Wandels ist es ganz wichtig, sich Zeit für das
            Wohlbefinden von Körper und Geist zu nehmen und gut zu sich zu
            schauen. Hier finden Sie Anregungen wie Sie Ihr Immunsystem stärken
            können und zu Ihrem Wohlbefinden schauen können.
          </p>

          <FigureContainer>
            <Figure src={ganzheitlich} alt="Ganzheitlich" />
          </FigureContainer>
        </StaticBlock>
        <StaticBlock>
          <Title>STÄRKUNG DES KÖRPERS UND DES IMMUNSYSTEMS</Title>
          <p>
            Das beste Mittel, um Erkrankungen vorzubeugen, ist die Stärkung des
            Immunsystems und des Körpers. Dafür können Sie ganz viel tun:
          </p>
          <ul>
            <li>
              <b>Ernährung</b>: ernähren Sie sich Gesund, mit frischem Gemüse
              und Obst. Meiden Sie Fertignahrung und zu viel Zucker.
            </li>
            <li>
              <b>Trinken</b>: trinken Sie Genug, ca. 2 l Wasser/Tee pro Tag.
              Tees mit Kräuter können die Vorbeugung unterstützen (z.B.
              Ingwertee mit Zitrone und Honig). Meiden Sie alkoholische
              Getränke.
            </li>
            <li>
              <b>Unterstützung des Immunsystems</b> mit: Echinacea Tropfen,
              Vitamin C und Zinc.
            </li>
            <li>
              <b>Bewegung</b>: die körperliche Betätigung ist Balsam für Körper
              und Seele. Versuchen Sie sich mindestens 30 Min. pro Tag zu
              bewegen.
              <br />
              <p>
                --> Zu Hause: können Sie Yoga, Tai Chi, Qi Gong, Atemübungen
                oder Fitness-Übungen machen. Es gibt viele Online-Angebote und
                Kurse.
              </p>
              <p>
                --> In der Natur: Schwimmen, Joggen, durch den Wald laufen
                (achten Sie auf social distancing und halten sie sich an die
                Anweisungen des BAG). Oder gehen Sie einfach für kurze Zeit nach
                draussen und tanken Sie die Energie der Natur und der Sonne.
              </p>{" "}
            </li>
            <li>
              <b>Aufsteigende Fussbäder</b>: die Fussbäder regulieren und
              fördern die Durchblutung im Kopf, Brust und Bauchbereich.
            </li>
            <li>
              <b>Schlaf</b>: genug Schlaf ist ganz wichtig für das Immunsystem.
              Empfohlen werden 6-9 h Schlaf pro Nacht .
            </li>
            <li>
              <b>Stress</b>: reduzieren Sie Stressfaktoren, machen Sie
              regelmässige Pausen. Meditieren und Yoga können dabei auch sehr
              hilfreich sein.
            </li>
          </ul>
          <FigureContainer>
            <Figure src={go_inside} alt="Gesunder Mensch">
              <em>
                Das Bild wurde mir weitergeleitet, <br />
                leider kenne ich die Quelle nicht, sorry!
              </em>
            </Figure>
          </FigureContainer>
        </StaticBlock>

        <StaticBlock>
          <Title>WAS KANN MAN MIT DER HOMÖOPATHIE TUN?</Title>
          <p>
            Die homöopathischen Mittel können bei einer Vielzahl von aktuen
            Krankheiten eingesetzt werden, auch bei viralen oder bakteriellen
            Infekte. Sollten Sie mögliche Covid-19-Symptome haben, dann
            kontaktieren Sie Ihren Arzt. Abhängig vom Verlauf und der
            Symptomatik kann zusätzlich ein passendes homöopathische Mittel
            verschrieben werden.
          </p>
          <h3>Macht eine Prophylaxe Sinn?</h3>
          <p>
            Eine Prophylaxe mit homöopathischen Mittel macht aus homöopathischer
            Sicht keinen Sinn. Die Homöopathie ist keine präventive
            Behandlungsmethode, sondern die Mittel werden individuell, anhand
            der gezeigten Symptomen verschrieben. Deshalb ist eine Prävention,
            ohne Symptome, nicht sinnvoll.
          </p>
        </StaticBlock>

        <StaticBlock>
          <Title>FÜR DEN GEIST UND DAS WOHLBEFINDEN</Title>
          <h3>Soziale Kontakte & Hilfe anbeiten</h3>
          <p>
            Soziale Kontakte sind für uns Menschen etwas ganz wichtiges. Schauen
            Sie, dass Sie den Kontakt zu Ihrer Familie und Freunden wahren
            können. Es gibt ganz viele Möglichkeiten wie Sie das tun können.
            Falls Sie jung und gesund sind, ist es etwas sehr erfüllendes,
            Freunden oder Nachbarn Hilfe anzubieten und sie zu unterstützen.
          </p>
          <p>
            In den letzten Jahren ist unsere Welt extrem digitalisiert geworden,
            man ruft sich kaum mehr an, sondern chattet nur noch miteinander.
            Ich finde es ganz schön, wie in dieser Krisensituation alle extrem
            hilfsbereit sind und sich gegenseitig unterstützen. Das ist z.B.
            etwas positives, was wir von dieser Pandemie mitnehmen können und
            auch später, nach der Krise, bewahren und weiterführen können : ).
          </p>
          <FigureContainer>
            <Figure src={kontakt} alt="Kontakt" />
          </FigureContainer>
          <h3>Zeit für mich</h3>
          <p>
            Wenn man für längere Zeit in einem Haushalt auf engem Raum zusammen
            lebt, ist es ganz wichtig, dass man sich immer wieder bewusst Zeit
            für sich nimmt. Ich weiss es ist nicht einfach, aber ich bin mir
            sicher, dass jeder von uns die Möglichkeiten hat sich bewusst 10 Min
            pro Tag für sich zu nehmen. Gehen Sie mal alleine 10 Min in den
            Garten oder wenn möglich spazieren. Legen Sie sich bewusst 15 Min
            auf das Sofa und lesen Sie einen interessanten Artikel oder ein
            tolles Buch.
          </p>
          <h3>Achstam im Alltag</h3>
          <p>
            Nutzen Sie die Gelegenheit, dass Sie nicht gestresst aus dem Haus
            rennen und von einem Termin zum nächsten hetzen müssen. Versuchen
            Sie achtsam im Alltag zu sein. Dazu kann ich folgende
            Achtsamkeitskarten wärmstens empfehlen! Ziehen Sie jeden Tag
            zufällig eine Karte und machen Sie, was darauf steht.
          </p>
          <ul>
            <li>
              <a href="https://www.exlibris.ch/de/buecher-buch/deutschsprachige-buecher/achtsamkeit-in-der-familie/id/9783845836300">
                Achstsamkeitskarten für die Familie, für dich, in der Liebe
              </a>
            </li>
          </ul>
        </StaticBlock>

        <StaticBlock>
          <Title>RÄUCHERN</Title>
          <p>
            Holen Sie die Natur in Ihre Wohnung. Das Räuchern von Kräuter und
            Harze ist ein alter Gebrauch, welcher in allen Kulturen verbreitet
            ist, von Egypten, Arabien, Indien, Südamerika bis hier in Europa.
            Das Räuchern wird für Rituale, Reinigung, Heilung oder einfach wegen
            den feinen Düften verwendet. Die Kräuter verbreiten einen angenehmen
            Duft im Raum und können auch heilende und desinfizierende Wirkung
            haben.
          </p>
          <p>
            Sie können mit Kohle räuchern (siehe Bild). Falls Ihnen der Geruch
            mit der Kohle zu intensiv ist, können Sie auch ein Teelicht mit
            einem Sieb verwenden. Der Duft ist dann viel milder und Sie können
            die Mischung über längere Zeit auf dem Sieb lassen.
          </p>
          <FigureContainer>
            <Figure src={incense} alt="incense" />
          </FigureContainer>
          <p>
            Zubehör zum Räuchern finden Sie in den Drogerien oder Online. Hier
            zwei Beispiele:
          </p>
          <ul>
            <li>
              Räucherkräuter und Zubehör{" "}
              <a href="https://www.raeucherkraeuter.ch/">
                (raeucherkräuter.ch)
              </a>
            </li>
            <li>
              Sehr feine Duftmischungen zum Räuchern{" "}
              <a href="https://www.raeucherfee.ch/shop-1/r%C3%A4uchermischungen-r%C3%A4ucherkr%C3%A4uter/">
                (raeucherfee.ch)
              </a>
            </li>
          </ul>
        </StaticBlock>

        <StaticBlock>
          <Title>DIE POSITIVE SEITE</Title>
          <p>
            Überall wo es Schatten gibt, gibt es auch Licht. Viele Patienten
            haben mir ihre positiven Erlebnissen im neuen Alltag geschildert.
            Schreiben Sie auf, welche positiven Sachen Sie durch Corona entdeckt
            haben und welche Erkenntnissen Sie gewonnen haben.
          </p>
          <br />
          <p>
            Und hier noch ein paar spannende Fragen zur Reflektion. Notieren Sie
            sich die Antworten auf ein Blatt Papier und lesen Sie diese in ein
            paar Monaten wieder ;-).{" "}
          </p>
          <ul>
            <li>
              Retrospektive, wie waren meine letzten drei Jahre? Was stimmt für
              mich? Was war gut und was möchte ich hingegen verändern?
            </li>
            <li>Wofür hatte ich in meinem "normalen" Alltag zu wenig Zeit?</li>
            <li>
              Welche positiven Erfahrungen, Erkenntnisse nehme ich aus dieser
              Krise?
            </li>
            <li>
              Wie kann ich die positiven Erkenntnisse beibehalten und auch nach
              der Krise pflegen und ausleben?
            </li>
            <li>Gibt es etwas, was ich in Zukunft verändern möchte?</li>
          </ul>
        </StaticBlock>

        <StaticBlock>
          <Title>SPANNENDE LINKS</Title>
          <ul>
            <li>
              <a href="https://www.youtube.com/watch?v=b0NFSxagQ64">
                Bleiben Sie zu Hause
              </a>
            </li>
            <li>
              <a href="https://www.ndr.de/nachrichten/info/podcast4684.html">
                Wissenschaftlicher Podcast über Corona (NDR)
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=6Af6b_wyiwI">
                Bill Gates | The next outbreak? We're not ready (Ted talk)
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=iyFT8qXcOrM">
                Interview Trevor Noah mit Bill Gates
              </a>
            </li>
          </ul>
        </StaticBlock>
      </StaticBlockContainer>
    </Container>
  </>
)

export default AktuellCoronaPage
